import cn from "classnames";

import {
  Icon,
  Button,
  Box,
  ModalWindowModern,
  ModalPaymentSuccessful,
  ModalPaymentFailed,
} from "components/atomsKit/Elements";
import { NameonChat } from "components/designAtom/Elements";

// @ts-ignore
import classes from "./ModalAlert.module.scss";

interface IProps {
  modalIsOpen?: boolean;
  closeModal: () => void;
  textAlertBtn?: string;
  onClick?: () => void;
  icon: string;
  allertMessage: string;
  children?: any;
  description?: any;
  zIndex?: number;
  allScreen?: boolean;
  mode?: string;
}

const ModalAlert = ({
  modalIsOpen,
  closeModal,
  textAlertBtn,
  onClick,
  icon,
  allertMessage,
  children,
  description,
  zIndex,
  allScreen,
  mode,
}: IProps) => {
  if (mode === "paySucces") {
    return (
      <ModalPaymentSuccessful
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        credits={description}
      />
    );
  }
  if (mode === "payFail") {
    return (
      <ModalPaymentFailed modalIsOpen={modalIsOpen} closeModal={closeModal} />
    );
  }
  return (
    <ModalWindowModern
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
      hideCloseButton
      // @ts-ignore
      zIndex={zIndex}
    >
      <div className={cn(classes.wrap, allScreen && classes.wrap_allScreen)}>
        <div className={classes.modalBody}>
          <button onClick={closeModal} className={classes.btn}>
            <Icon icon={"ok"} className={classes.btn} color={"#CDD3DA"} />
          </button>
          <h2 className={classes.textAlert}>{allertMessage}</h2>
          {description && (
            <Box className={classes.description}>
              <NameonChat
                text={description}
                margin="0 0 20px 0"
                textAlign={"center"}
              />
            </Box>
          )}
          {children ||
            (textAlertBtn !== "" && (
              <Button
                onClick={onClick || closeModal}
                text={textAlertBtn}
                icon={icon}
                className={classes.alertBtn}
              />
            ))}
        </div>
      </div>
    </ModalWindowModern>
  );
};

export default ModalAlert;
