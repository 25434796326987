import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "utils/requests";

import { readStatus } from "redux/chat/slice";
import { togleGlobalModal, togleModalContent } from "redux/interface/slice";

export const chatCounter = createAsyncThunk(
  "interface/chatcounter",
  async (external_id, thunkAPI) => {
    try {
      const responseChat = await getRequest(
        `/api/chat/counters?user_id=${external_id}`
      );
      const responseMailbox = await getRequest(
        `/api/mailbox/count?user_id=${external_id}`
      );

      return {
        chat: responseChat.counters?.unanswered,
        mailbox: responseMailbox.result,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchUploadFiles = createAsyncThunk(
  "interface/fetchuploadfiles",
  async (_, thunkAPI) => {
    try {
      const photos = await getRequest(`/api/files/images`);
      const videos = await getRequest(`/api/files/videos`);
      const audios = await getRequest(`/api/files/audios`);

      return {
        videos: videos.videos,
        photos: photos.images,
        audios: audios.audios,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchTransaction = createAsyncThunk(
  "interface/fetchtransaction",
  async (_, thunkAPI) => {
    try {
      const configRes = await getRequest(`/api/v3/ab-test/config/transaction`);

      const isRegister = window?.location?.search?.includes(
        "registration=success"
      );

      if (configRes) {
        await postRequest("/api/v3/front-sync/transaction", {
          isRegister,
          config: {
            register: Array.isArray(configRes?.register)
              ? configRes?.register
              : [],
            recycle: Array.isArray(configRes?.recycle)
              ? configRes?.recycle
              : [],
            infinity: Array.isArray(configRes?.infinity)
              ? configRes?.infinity
              : [],
          },
          registerSettings: configRes?.startTransaction ?? {},
        });
      }
      // return configRes;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postActionTransaction = async (transactionState) => {
  const {
    type,
    delay,
    lifeTime,
    configType,
    limit = 0,
    isInvite = false,
    connect = 0,
    delayConnect = 1,
  } = transactionState?.transaction;

  const subSendByConfigType = async (attempt) => {
    attempt = attempt - 1;

    const transactionRes = await postRequest(
      `/api/v3/transaction/ask/${configType?.toLowerCase()}`,
      {
        type,
        lifeTime,
        delay,
        limit,
        connect,
        delayConnect,
      }
    );

    if (transactionRes?.status || attempt < 1) {
      postRequest(`/api/v3/front-sync/transaction/next`, {});
    } else {
      subSendByConfigType(attempt);
    }
  };

  setTimeout(async () => {
    const isCheck = await postRequest(`/api/v3/front-sync/transaction/check`, {
      delay: +transactionState?.transaction?.delay,
    });

    if (isCheck?.status) {
      const transactionRes = await postRequest(
        `/api/v3/transaction/ask/${configType?.toLowerCase()}${
          isInvite ? "/yes" : ""
        }`,
        {
          type,
          lifeTime,
          delay,
          limit,
          connect,
          delayConnect,
        }
      );

      if (transactionRes?.status) {
        postRequest(`/api/v3/front-sync/transaction/next`, {});
      } else {
        subSendByConfigType(10);
      }
    }
  }, +transactionState?.transaction?.delay * 1000);
};

export const fetchVideoShow = createAsyncThunk(
  "interface/fetchvideoshow",
  async (_, thunkAPI) => {
    try {
      const videoShow = await getRequest(`/api/v3/search/video`);
      return videoShow.response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchSayHalloList = createAsyncThunk(
  "interface/fetchsayhallolist",
  async (_, thunkAPI) => {
    try {
      const res = await getRequest(`/api/chat/sayHelloList`);
      return res.list;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const dataModalContent = createAsyncThunk(
  "interface/datamodalcontent",
  async (payload, thunkAPI) => {
    try {
      const REQ_BODY = {
        chat_uid: payload.chat_uid,
        message_id: payload.media_id,
        media_id: payload.media_id,
        user_id: payload.user_id,
      };
      if (payload.content.payed) {
        await postRequest("/api/chat/viewMedia", REQ_BODY);
        thunkAPI.dispatch(
          readStatus({
            chat_uid: REQ_BODY.chat_uid,
            message_id: REQ_BODY.message_id,
            original: payload?.content?.original,
            payed: true,
          })
        );
        thunkAPI.dispatch(togleModalContent(true));
        return [payload.content];
      }

      const res = await postRequest("/api/chat/readVideo", REQ_BODY);
      if (res?.status) {
        await postRequest("/api/chat/viewMedia", REQ_BODY);
        thunkAPI.dispatch(
          readStatus({
            chat_uid: REQ_BODY.chat_uid,
            message_id: REQ_BODY.message_id,
            original: res?.message_content,
            payed: true,
          })
        );
        thunkAPI.dispatch(togleModalContent(true));
        return [{ ...payload.content, original: res?.message_content }];
      } else {
        thunkAPI.dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "You lack credits",
            description: `You need to get more credits to be able to open video`,
            btnText: "Get credits",
          })
        );
        return thunkAPI.rejectWithValue(res);
      }
    } catch (e) {
      thunkAPI.dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: "You lack credits",
          description: `You need to get more credits to be able to open video`,
          btnText: "Get credits",
        })
      );
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchCheckNotify = createAsyncThunk(
  "interface/fetchchecknotify",
  async (_, thunkAPI) => {
    try {
      const res = await postRequest(`/api/notify/check`, {});
      return res?.result;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
