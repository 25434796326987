import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  Stick,
  Avatar,
  SliderContent,
  Button,
  Counter,
  Box,
} from "components/atomsKit/Elements";

import { objectToArray } from "utils/common";

import cn from "classnames";

// @ts-ignore
import classes from "./LetterCard.module.scss";

interface IProps {
  blure?: boolean;
  freeRead?: boolean;
  cardInfo: {
    female_id: number;
    male_id: number;
    public_external_id: number;
    chat_id: number;
    read_status?: number;

    sender_name: string;
    sender_id: number;
    sender_age: number;
    sender_city: string;
    sender_country_icon: string;
    sender_image: string;

    recipient_name: string;
    recipient_age: number;
    recipient_city: string;
    recipient_country_icon: string;
    recipient_image: string;

    is_favorite: number;
    is_first: number;
    sender_online: number;
    answered: number;
    message_content: string;
    unread_count: number;
    images: { link: string; id: number }[];
    id: number;
  };
  attachments?: any;
  handleDel: (chat_id: number) => void;
  handleRestore: (chat_id: number) => void;
  handleContent?: any;
  price:
    | {
        [key: string]: number;
      }
    | undefined;
  filterChat: string;
}

const LetterCard = ({
  blure,
  freeRead,
  cardInfo,
  attachments = [],
  handleDel,
  handleContent,
  handleRestore,
  price,
  filterChat,
}: IProps) => {
  const {
    id,
    female_id,
    public_external_id,
    male_id,
    chat_id,
    read_status,

    sender_name,
    sender_id,
    sender_age,
    sender_city,
    sender_country_icon,
    sender_image,

    recipient_name,
    recipient_age,
    recipient_city,
    recipient_country_icon,
    recipient_image,

    is_favorite,
    sender_online,
    answered,
    message_content,
    unread_count,
  } = cardInfo;

  const isUserCard = male_id === sender_id;

  const splitAndBlur = (text: string) => {
    const maxLength = 25;
    const lines = text.split("\n");
    const firstPart = lines.map((line) => line.slice(0, maxLength)).join("\n");
    const restPart = lines.map((line) => line.slice(maxLength)).join("\n");

    return (
      <Fragment>
        {blure ? firstPart : firstPart + restPart}
        {blure && restPart && (
          <Fragment>
            <span className={classes.blur}>{restPart}</span>
          </Fragment>
        )}
      </Fragment>
    );
  };

  const navigate = useNavigate();

  return (
    <section className={classes.wrapLetters}>
      <div
        className={cn(
          classes.wrapCard,
          !read_status && !isUserCard && classes.wrapCard_notReading
        )}
      >
        <Link
          to={`/letter?profileId=${public_external_id}`}
          className={classes.link}
        >
          <div className={classes.wrapInfo}>
            <div className={classes.wrpaInfoAvatar}>
              {isUserCard ? <p className={classes.to}>To:</p> : <></>}
              <Avatar
                imgLink={isUserCard ? recipient_image : sender_image}
                isFavorite={!!is_favorite}
                isOnline={!!sender_online}
              />
              <div className={classes.avatartTextInfo}>
                <p className={classes.id}>ID: {public_external_id}</p>
                <p className={classes.name}>
                  {isUserCard ? recipient_name : sender_name},{" "}
                  {isUserCard ? recipient_age : sender_age}
                </p>
                <p className={classes.city}>
                  <img
                    src={
                      isUserCard ? recipient_country_icon : sender_country_icon
                    }
                    alt=""
                    width="15px"
                    loading="lazy"
                    className={classes.flag}
                  />
                  {isUserCard ? recipient_city : sender_city}
                </p>
              </div>
            </div>
            <Box flex>
              {freeRead && (
                <Stick text={"Free reading"} className={classes.freeReading} />
              )}
              {unread_count > 0 ? (
                <Counter counter={unread_count} bigMode />
              ) : (
                <Stick
                  className={cn(
                    classes.stick,
                    !answered && classes.stick_error
                  )}
                  text={!!answered ? "Answered" : "Unansvered"}
                  error={!!answered ? false : true}
                />
              )}
            </Box>
          </div>
          <p className={classes.text}>{splitAndBlur(message_content)}</p>
        </Link>
        {attachments.length > 0 && (
          <>
            <div className={classes.line} />
            {!objectToArray(attachments)?.every(
              (item: any) => +item?.attachment_free
            ) && (
              <p className={classes.costContentText}>
                {`The cost of each attachment is: photo - ${price?.GET_IMAGE_MAIL}
              credits, video - ${price?.GET_VIDEO_MAIL_NEW} credits, audio -
              ${price?.GET_AUDIO_NEW} credits. Special bonus - the first photo is
              free.`}
              </p>
            )}

            <SliderContent
              handleContent={handleContent}
              girlsList={attachments}
              chat_id={chat_id}
              id={id}
            />
          </>
        )}
      </div>
      <div className={classes.btnsWrap}>
        {filterChat !== "trash" ? (
          <>
            {!answered && (
              <Button
                onClick={() => navigate(`/letter?profileId=${public_external_id}`)}
                text={"Reply"}
                icon={"back"}
                isWhite
                borderOff
                noBg
                className={classes.btnPanel}
              />
            )}
            <Button
              onClick={() => navigate(`/profile-woman/${female_id}`)}
              text={"Profile"}
              icon={"user"}
              isWhite
              borderOff
              noBg
              className={classes.btnPanel}
            />
            <Button
              disabled={!read_status && !isUserCard}
              onClick={() => {
                handleDel(chat_id);
              }}
              text={"Delete"}
              icon={"been"}
              isWhite
              borderOff
              noBg
              className={classes.btnPanel}
            />
          </>
        ) : (
          <Button
            disabled={!read_status && !isUserCard}
            onClick={() => {
              handleRestore(id);
            }}
            text={"Restore"}
            iconSvg={"c3l-restore-msg"}
            isWhite
            bgImg
            borderOff
            noBg
            className={classes.btnPanel}
          />
        )}
      </div>
    </section>
  );
};

export default LetterCard;
