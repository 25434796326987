import cn from "classnames";

import { Box, Button, AudioPlayer } from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";

// @ts-ignore
import classes from "./SliderContent.module.scss";

interface IProps {
  handleDel?: any;
  girlsList: any;
  className?: any;
  handleContent?: any;
  chat_id?: number;
  id?: number;
}

const SliderContent = ({
  girlsList,
  handleDel,
  handleContent,
  chat_id,
  id,
  className,
}: IProps) => {
  const isBlure = (item: any) => {
    return item.attachment_free === 0 && item.attachment_payed === 0;
  };

  return (
    <Box className={cn(classes.wrapSlider, className)}>
      {girlsList.map((content: any, index: number) =>
        content?.message_type === "SENT_IMAGE" ||
        content?.message_type === "SENT_VIDEO" ? (
          <Box
            className={classes.btnImg}
            key={index}
            onClick={() => {
              handleContent({
                original: isBlure(content) ? content.thumb_link : content.link,
                thumbnail: isBlure(content) ? content.thumb_link : content.link,
                type: content?.message_type,
                isNotPayed: isBlure(content),
                content: content,
                chat_id: chat_id,
                id: id,
              });
            }}
          >
            {handleDel && (
              <Button
                text=""
                isWhite
                borderOff
                className={classes.delPhotoBtn}
                iconSvg="delete-photo"
                onClick={() => handleDel(content)}
              />
            )}
            {content?.message_type === "SENT_VIDEO" && (
              <Image name="play-video" className={classes.playLogo} />
            )}
            {content?.message_type === "SENT_IMAGE" ? (
              <img
                src={isBlure(content) ? content.thumb_link : content.link}
                alt=""
                className={cn(classes.img)}
                loading="lazy"
              />
            ) : isBlure(content) ? (
              <img
                src={content.thumb_link}
                alt=""
                className={cn(classes.video, isBlure(content) && classes.blur)}
                loading="lazy"
              />
            ) : (
              <video
                width="96"
                height="56"
                controls={false}
                preload="metadata"
                className={cn(isBlure(content) && classes.blur)}
              >
                <source src={`${content?.link}#t=0.6`} type="video/mp4" />
              </video>
            )}
          </Box>
        ) : (
          <Box className={classes.btn} key={index}>
            {handleDel && (
              <Button
                text=""
                isWhite
                borderOff
                className={classes.delPhotoBtn}
                iconSvg="delete-photo"
                onClick={() => handleDel(content)}
              />
            )}
            <AudioPlayer
              src={content.link}
              name={content.title}
              isPlay={!isBlure(content)}
              handleContent={(playFunc) =>
                handleContent(
                  { ...content, isNotPayed: isBlure(content) },
                  playFunc
                )
              }
            />
          </Box>
        )
      )}
    </Box>
  );
};

export default SliderContent;
